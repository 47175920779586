.bsc-deposit-modal {
  .bsc-deposit-modal-header {
    display: flex;
    flex-direction: column;
    padding: 4vh;
    padding-bottom: 2vh;
    border-bottom: 1px solid #464646;
    & > span:nth-child(1) {
      color: #FFF4E3;
      font-family: poppins_extrabold;
      font-size: 20px;
    }

    & > span:nth-child(2) {
      color: #FDD284;
      font-family: poppins_medium;
      font-size: 16px;
    }
  }
  .bsc-deposit-modal-content {
    display: flex;
    flex-direction: column;
    padding: 4vh;
    padding-top: 2vh;
    .bsc-deposit-modal-content-top {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1vh;
      & > span {
        font-size: 14px;
      }
      & > span:nth-child(1) {
        color: #FDD284;
        margin-right: 5px;
      }
      & > span:nth-child(2) {
        color: #C4BCAA;
      }
    }
    .bsc-deposit-modal-content-mid {
      margin-bottom: 2.2vh;
      & > .bsc-input {
        & > .bsc-input-right {
          & > span {
            color: #FFFFFF;
            font-size: 14px;
            font-family: poppins_medium;
            margin-right: 6px;
          }
        }
      }
    }
    .bsc-deposit-modal-content-bottom {
      display: flex;
      justify-content: space-between;
    }
  }
}