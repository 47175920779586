.iconMedia{
    padding-left: 30px;
}
.footer{
    text-align: center;
     background-color:#131418;
}
.footer-top{
    background-color: #131418;
}
.footer-title {
    background-color: #252729;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    padding: 10px 20px;
    letter-spacing: 0.025em;
    color: #FFFFFF;
    text-align: left;
}
.footer-content{
    text-align: left;
    padding: 5px 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.025em;
    color: #9F9F9F;
}
.footer-tab{
    width: 60%;
    padding-right: 15px;
}
.footer-link{
    color: #9F9F9F;
}
.footer-link:hover {
    color: #FFD60A;
}
.footer-coppy{
    background-color: #000000;
    height: 54px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.025em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #9F9F9F;
}
.footer-coppy-text{
    padding-top: 17px;
}
.footer-icon{
    margin-top: 35px;
}



  .c-footerHome {
      background: #1c1c1c;
      width: calc(100% + 56px);
      margin: 0 -28px;
  }

  .c-footerHome_sologun {
      color: #8B8C92;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.025em;
  }

  .c-footerHome__link {
      padding: 60px 75px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  }

  .c-footerHome__row {
      width: 16.6666666667%;
  }

  .c-footerHome__row.w-2 {
      width: 33.3333333333%;
  }

  .c-footerHome__row p {
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.025em;
      color: #FFFFFF;
      margin-bottom: 18px;
      margin-top: 5px;
  }

  .c-footerHome__row ul {
      display: block;
      list-style-type: none;
      margin-bottom: 0;
      padding:0
  }

  .c-footerHome__row ul li {
      display: flex;
  }

  .c-footerHome__row ul li+li {
      margin-top: 15px;
      color:#8F8E93
  }

  .c-footerHome__row ul li a {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.025em;
      color: #8F8E93;
      transition: 0.3s ease-in-out;
  }

  .c-footerHome__row ul li a:hover {
      color: #FFD60A;
  }

  .c-footerHome__copy {
      background: #000;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.025em;
      color: #9F9F9F;
      padding: 25px;
      margin: 0;
  }

  .c-sidebar__logo.footer {
    width: 50%;
    margin: 0;
    background: transparent;
  }

  .c-sidebar__logo img {
     width: 100%;
     background: transparent; 
  }
 
@media (max-width: 1199px) {
    .c-footerHome__row.w-2{
        width: 100%
    }
    .c-sidebar__logo.footer{
        width: 30%
    }
    .footer-icon{
        margin-top: 20px;
        margin-bottom: 20px;
    } 
    .c-footerHome__link{
        padding:40px 50px
    }
    .c-footerHome__row ul li a{
        font-size: 14px;
    }
    .c-footerHome_sologun{
        font-size: 14px;
    }
}
@media (max-width: 414px) {
.c-footerHome{
    width: 100%;
    margin: 0px
}
    .footer-icon {
        margin-top: 25px;
    }

    .footer-tab {
        width: 100%;
        padding-right: 100px;
        padding-top: 30px;
        text-align: start;
    }

    .footer-title {
        padding-left: 12px;
    }

    .footer-content {
        padding-left: 12px;
        text-align: start;
    }

    .footer-coppy-text {
        padding-top: 9px;
    }

    .c-footerHome_sologun {
        font-size: 14px;
    }

    .c-footerHome__link {
        padding: 25px;
        justify-content: space-between;
        display: flex;
    }

    .c-footerHome__row {
        width: 44%;
    }

    .c-footerHome__row.w-2 {
        margin-bottom: 0px;
        width: 100%;
        margin-bottom: 10px;
    }

    .c-footerHome__row+.c-footerHome__row {
        margin-top: 20px;
    }

    .c-footerHome__row p {
        margin-bottom: 30px;
    }
    .c-footerHome__row ul li a{
        font-size: 12px;
    }
    .c-footerHome__row p{
        margin-bottom: 10px;
    }
    .footer-coppy-text{
        font-size: 12px;
    }
}