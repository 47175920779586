.all {
  width: 1050px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}
.bsc-nav-tabs {
  position: relative;
}
.main-pool-card {
  width: 1050px;
  max-width: 100%;
  margin: 0 auto;
}
.box-list-grid {
  position: absolute;
  margin-right: auto;
  top: -75px;
  left: 0;
  margin-left: 0px !important;
}
.box-list-grid.nav-tabs {
  background-color: transparent !important;
}
.box-list-grid .nav-item.nav-link.active {
  background-color: transparent !important;
}
.box-list-grid.nav-tabs > .nav-item {
  padding: 0 !important;
  padding-right: 24px !important;
}
.box-list-item {
  padding: 0;
  pointer-events: none;
}
.box-list-item:nth-child(1) .ant-collapse-header {
  border-radius: 4px 4px 0 0 !important;
}
.box-list-item:last-child .ant-collapse-header {
  border-radius: 0 0 4px 4px !important;
}
.box-list-item:last-child .ant-collapse > .ant-collapse-item {
  border-bottom: 0px solid transparent;
}
.box-list-item:nth-child(1) {
  padding-top: 20px;
}
.box-list-item .ant-collapse {
  background: transparent;
}
.box-header-list .item-tow-part .box-logo .logo-big {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  padding: 6px;
}
.box-header-list .item-tow-part .box-logo {
  position: relative;
  width: 50px;
}
.box-header-list .item-tow-part .box-logo .logo-small {
  position: absolute;
  width: 23px;
  height: 23px;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: #fff;
  padding: 2px;
}
.box-header-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.box-header-list .item-tow-part {
  width: calc(1.7 / 12 * 100%);
}
.box-header-list .item-tow-part:nth-child(1) {
  /* width: 23%; */
  width: 5%;
}
.box-header-list .item-tow-part:nth-child(6) {
  width: calc(1.7 / 12 * 100%);
}
.box-header-list .item-tow-part:last-child {
  width: 6%;
}
.box-header-list .item-tow-part.farm-lp:first-child {
  width: 20%;
}
.box-header-list .item-tow-part.farm-lp:last-child {
  width: 13%;
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.box-list-item .anticon.anticon-right.ant-collapse-arrow {
  position: absolute;
  right: 0;
  display: none;
}
.box-list-item .box-text-logo {
  margin-left: 15px;
}
.box-list-item .box-text-logo .text-big {
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
}
.box-list-item .box-text-logo .text-small {
  margin-bottom: 0;
  color: #9f9f9f;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.box-list-item .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background: #1c1b1b;
  border: 0px solid #333333;
  padding: 20px;
}
.box-list-item.ido-pool
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  background: #2e2e2e;
  border: 0.5px solid #333333;
}
.box-list-item .ant-collapse > .isMigrate .ant-collapse-header {
  background: #333333;
  border: 0.5px solid #333333;
}
.box-list-item .ant-collapse {
  border: 0px transparent;
}
.box-list-item .ant-collapse > .ant-collapse-item {
  border-bottom: 0.5px solid #575757;
}
.box-header-list .item-tow-part .box-earned .text-earned {
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #9f9f9f;
  font-family: poppins_medium;
}
.box-header-list .item-tow-part .box-earned {
  margin-left: 42px;
}
.box-header-list .item-tow-part .box-earned .total-coin {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #fff;
  margin-bottom: 0;
  display: flex;
}
.box-header-list .item-tow-part .box-earned .total-coin > span > div {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.03em;
  margin-bottom: 0;
  color: #fff;
  margin-right: 5px;
}
.box-header-list .item-tow-part .box-earned .total-usd {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #fdd284;
  margin-bottom: 0;
  display: flex;
}
.box-header-list .item-tow-part .box-earned .total-usd > div {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.03em;
  margin-bottom: 0;
  margin-right: 5px;
}
.box-header-list .item-tow-part .box-apr .text-apr {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #9f9f9f;
  margin-bottom: 5px;
  font-family: poppins_medium;
}
.box-header-list .item-tow-part .box-apr .number-apr {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #f0eaea;
  margin-bottom: 0;
}
.box-header-list .item-tow-part .box-apr .number-apr .text-small {
  margin-bottom: 0;
}
.box-header-list .item-tow-part .box-img-calculating {
  margin-left: 22px;
}
.box-header-list .item-tow-part .box-total-staked .text-total-staked {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #9f9f9f;
  margin-bottom: 5px;
  font-family: poppins_medium;
}
.box-header-list .item-tow-part .box-total-staked .number-total-staked {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #f0eaea;
  margin-bottom: 0;
}
.box-header-list .item-tow-part .box-total-staked .number-total-staked > div {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #f0eaea;
  margin-right: 5px;
  margin-bottom: 0;
}
.box-header-list .item-tow-part .box-ends-in .number-ends-in {
  margin-bottom: 0;
}
.box-header-list .item-tow-part .box-ends-in .number-ends-in .mb-0 {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #b1b1b1;
  font-family: poppins_medium;
}
.box-header-list
  .item-tow-part
  .box-ends-in
  .number-ends-in
  .d-flex.align-items-center {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #f0eaea !important;
  margin-right: 5px;
  margin-bottom: 0;
  margin-top: 3px;
}
.box-header-list
  .item-tow-part
  .box-ends-in
  .number-ends-in
  .d-flex.align-items-center
  > div {
  color: #f0eaea !important;
}
.box-header-list .btn-bsc-link button div > span {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #ffd60a;
}
.box-list-item .ant-collapse-content-box {
  background: #1b1b1c;
  pointer-events: auto;
  padding: 0 20px 20px 20px;
}
.box-content-body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #070707;
  padding: 20px;
  border-radius: 4px;
}
.box-content-body .item-three-part {
  width: 25%;
}
.box-content-body .item-four-part {
  width: 37.5%;
}
.box-content-body .item-three-part .text-view-info {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #0dba88;
  margin-bottom: 10px;
}
.box-content-body .item-three-part .text-view-info a {
  color: #ffd60a;
}
.box-content-body .item-three-part .text-view-info img {
  margin-left: 5px;
  width: 15px;
}
.box-content-body .item-three-part .text-ends-in .mb-0 {
  font-size: 14px;
  line-height: 21px;
  color: #fff;
}
.box-content-body
  .item-three-part
  .text-ends-in
  .d-flex.align-items-center
  > div {
  color: #0dba88 !important;
}
.box-content-body .item-four-part .box-harvest .harvest-left .text-harvest {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #9f9f9f;
  margin-bottom: 5px;
}
.row-flex > div > span {
  color: #fff;
  font-size: 16px;
}
.box-content-body .item-four-part .box-harvest .harvest-left .total-coin {
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.03em;
  color: #fdd284;
  margin-bottom: 10px;
}
.box-content-body .item-four-part .box-harvest .harvest-left .total-coin > div {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.03em;
  margin-bottom: 10px;
}
.box-content-body .item-four-part .box-harvest .harvest-left .total-usd {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #fdd284;
  display: flex;
  margin-bottom: 0;
}
.box-content-body .item-four-part .box-harvest .harvest-left .total-usd > div {
  margin-right: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  display: flex;
  margin-bottom: 0;
}
.box-content-body .item-four-part .box-harvest .harvest-right .btn-bsc {
  margin: 0;
}
.box-content-body
  .item-four-part
  .box-harvest
  .harvest-right
  .btn-bsc-disabled {
  background: #313131;
}
.box-content-body .item-four-part .box-harvest {
  background: #1b1b1c;
  border: 0px solid #333333;
  box-sizing: border-box;
  border-radius: 0;
  padding: 15px 20px;
  margin-left: 30px;
  min-height: 140px;
  border-radius: 4px;
}
.box-content-body .item-four-part .box-start-staking {
  background: #1b1b1c;
  border: 0px solid #333333;
  box-sizing: border-box;
  border-radius: 0;
  padding: 15px 20px;
  margin-left: 30px;
  min-height: 140px;
  border-radius: 4px;
}
.box-content-body .item-four-part .box-start-staking > div {
  color: #fff;
}
.box-content-body .item-four-part .box-start-staking .text-start-staking {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-bottom: 5px;
}
.box-content-body .item-four-part .box-start-staking .btn-bsc-common {
  margin: 0;
  border-radius: 4px !important;
}
.mar-t-5 {
  margin-top: 5px !important;
}
.mar-t-10 {
  margin-top: 10px !important;
}
.box-list-item .ant-collapse-content {
  border-top: 0px transparent;
}
.box-list-item .ant-collapse-content {
  background: #212121 !important;
}
.stake-only {
  position: absolute;
  top: 8px;
  left: 200px;
}
.stake-only .text-stake-only {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #dbdad8;
  margin-left: 10px;
}
.main-pool-card {
  position: relative;
}
.stake-only .ant-switch-checked {
  background-color: #fdd284 !important;
}
.stake-only .ant-switch {
  background: #2e2e2e;
}
.box-img-list .anticon {
  font-size: 28px;
  color: #2e2e2e;
}
.box-list-grid .nav-item.nav-link.active .anticon {
  color: #ffd60a !important;
}
.back-to-top {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #0dba88;
  padding: 15px 0;
  margin: 0px 70px;
  text-align: center;
  border: 0.5px solid #333333;
  border-top: 0.5px solid #333333;
  border-radius: 18px;
}
.back-to-top button {
  background: transparent;
  border: 0px solid transparent;
}
.back-to-top img {
  margin-left: 5px;
}

.dis-mobile {
  display: none;
}

.box-search-stake {
  position: absolute;
  right: 0;
  top: -23px;
}
.box-search-stake .ant-input {
  background-color: #1b1b1c;
  color: #fff;
  border: 1px solid #595959;
}
.box-search-stake .ant-btn {
  background-color: #2e2e2e;
  color: #fdd284;
  border: 1px solid #fdd284;
}
.box-search-stake .anticon svg {
  display: inline-block;
  fill: #fdd284;
  margin-top: -7px;
}
.ant-input-search
  .ant-input:hover
  + .ant-input-group-addon
  .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search
  .ant-input:focus
  + .ant-input-group-addon
  .ant-input-search-button:not(.ant-btn-primary) {
  border-color: #fdd284;
}
.box-search-stake .ant-input-search .ant-input:hover,
.box-search-stake .ant-input-search .ant-input:focus {
  border-color: #fdd284;
}

.box-header-list .item-tow-part .height-80 {
  height: 80px;
}
/* .box-header-list .item-tow-part .box-apr {
  margin-left: 50px;
} */
.btn-bsc-common button {
  pointer-events: auto;
}
.button-show-modal {
  padding: 0;
  border: 0px solid transparent;
  background: transparent;
  pointer-events: auto;
}
.modal-roi-stake {
  width: 400px !important;
}
.modal-roi-stake .ant-modal-content {
  background: transparent;
}
.modal-roi-stake .ant-modal-header {
  background: #212121;
  border-bottom: 1px solid #fdd284;
  border-radius: 18px 18px 0 0;
}
.modal-roi-stake .ant-modal-header .ant-modal-title {
  color: #fdd284;
}
.modal-roi-stake .anticon svg {
  fill: #fdd284;
}
.modal-roi-stake .ant-modal-close-x {
  line-height: 48px;
}
.modal-roi-stake .ant-modal-footer {
  display: none;
}
.modal-roi-stake .ant-modal-body {
  background: #212121;
  color: #fff;
  border-radius: 0 0 18px 18px;
}
.box-conent-modal .table-modal table {
  width: 100%;
}
.box-conent-modal .table-modal table tr th {
  width: 33.33%;
  text-align: center;
  color: #ffd60a !important;
  padding-bottom: 5px;
}
.box-conent-modal .table-modal table tr td {
  text-align: center;
  padding-bottom: 5px;
  color: #b1b1b1;
}
.list-desciption {
  margin-top: 15px;
}
.list-desciption li {
  font-size: 12px;
  margin-bottom: 5px;
  color: #b1b1b1;
}
.button-get-link button {
  background: transparent;
  color: #0dba88;
  text-align: center;
  width: 100%;
  border: 0px solid transparent;
  font-size: 16px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.button-get-link button img {
  margin-left: 5px;
}
.main-pool-card .nav.nav-tabs {
  margin-bottom: 15px;
}
.box-header-list .item-tow-part:nth-child(2) {
  width: 18%;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .box-header-list .item-tow-part.farm-lp:last-child {
    width: 10%;
  }
  .box-header-list .item-tow-part:nth-child(1) {
    width: 6% !important;
  }
  .box-header-list .item-tow-part:nth-child(2) {
    width: 16%;
  }
  .box-header-list .item-tow-part:nth-child(3) {
    width: 14%;
  }
  .box-header-list .item-tow-part:nth-child(4) {
    width: 14%;
  }
  .box-header-list .item-tow-part:nth-child(5) {
    width: 15%;
  }
  .box-header-list .item-tow-part:nth-child(6) {
    width: 14% !important;
  }
  .box-header-list .item-tow-part:last-child {
    width: 6%;
  }
  .box-header-list .item-tow-part .box-apr {
    margin-left: 10px;
  }
}
/* @media screen and (min-width: 1491px) {
  .box-header-list .item-tow-part:nth-child(2) {
    width: 18%;
  }
  } */
@media screen and (min-width: 1200px) and (max-width: 1490px) {
  .box-header-list .item-tow-part:nth-child(1) {
    width: 6% !important;
  }
  .box-header-list .item-tow-part:nth-child(2) {
    width: 16%;
  }
  .box-header-list .item-tow-part:nth-child(3) {
    width: 14%;
  }
  .box-header-list .item-tow-part:nth-child(4) {
    width: 12%;
  }
  .box-header-list .item-tow-part:nth-child(5) {
    width: 15%;
  }
  .box-header-list .item-tow-part:nth-child(6) {
    width: 15% !important;
  }
  .box-header-list .item-tow-part:last-child {
    width: 6%;
  }
  .box-list-item .box-text-logo .text-big {
    font-size: 14px;
  }
  .box-header-list .item-tow-part .box-logo {
    width: 60px;
  }
  .box-header-list .item-tow-part .box-logo .logo-big {
    width: 60px;
    height: 60px;
  }
  .box-header-list .item-tow-part .box-logo .logo-small {
    width: 20px;
    height: 20px;
  }
  .box-header-list .item-tow-part .box-total-staked .number-total-staked > div {
    font-size: 14px;
  }
  .box-header-list .item-tow-part .box-total-staked .number-total-staked {
    font-size: 14px;
  }
  .box-header-list .item-tow-part .box-apr .number-apr {
    font-size: 14px;
  }
  .box-header-list
    .item-tow-part
    .box-ends-in
    .number-ends-in
    .d-flex.align-items-center {
    font-size: 14px;
  }
  .box-header-list
    .item-tow-part
    .box-ends-in
    .number-ends-in
    .d-flex.align-items-center
    > div {
    font-size: 14px;
  }
  .box-header-list .item-tow-part .box-img-calculating {
    margin-left: 5px;
  }
  .box-header-list .item-tow-part .box-earned {
    margin-left: 20px;
  }
}

.box-content-body .item-three-part .text-view-info .d-flex.align-items-center {
  align-items: center !important;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #0dba88;
}
.pf-5 {
  padding-left: 5px !important;
}
.text-schedules {
  color: #ffd60a;
  font-size: 15px;
  font-weight: 500;
}
.anticon {
  vertical-align: 0 !important;
}
@media screen and (max-width: 901px) {
  .dis-none-901 {
    display: none;
  }
  .box-header-list .item-tow-part:nth-child(1) {
    width: 6%;
  }
  .box-header-list .item-tow-part:nth-child(2) {
    width: 18%;
  }
  .box-header-list .item-tow-part:nth-child(3) {
    width: 18%;
  }
  .box-header-list .item-tow-part:nth-child(4) {
    width: 16%;
  }
  .box-header-list .item-tow-part:nth-child(5) {
    width: 18%;
  }
  .box-header-list .item-tow-part:nth-child(6) {
    width: 16%;
  }
  .box-header-list .item-tow-part:nth-child(7) {
    width: 16%;
  }
  .box-header-list .item-tow-part:nth-child(8) {
    width: 7%;
  }
}
@media screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
  .box-content-body
    .item-three-part
    .text-view-info
    .d-flex.align-items-center {
    align-items: center !important;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: #fff;
  }
  .show-des {
    display: none;
  }
  .main-pool-card .box-list-grid.nav.nav-tabs {
    margin-bottom: 0 !important;
    margin-top: -5px;
    margin-left: 0 !important;
  }
  .main-pool-card .nav.nav-tabs {
    margin-left: auto;
    margin-bottom: 75px;
  }
  .box-list-item {
    padding: 0;
    pointer-events: auto;
  }
  .main-pool-card {
    margin-top: 50px;
  }
  .box-list-grid {
    position: absolute;
    margin-right: auto;
    top: -110px;
    left: 0;
    margin-left: 15px !important;
  }
  .stake-only {
    position: absolute;
    top: -45px;
    right: 15px;
  }
  .dis-none {
    display: none;
  }
  .box-header-list .item-tow-part {
    width: 30%;
  }
  .box-header-list .item-tow-part .box-logo {
    width: 50px;
    margin-bottom: 5px;
  }

  .box-header-list .item-tow-part .box-logo .logo-big {
    width: 50px; 
  }
  .box-header-list .item-tow-part .box-logo .logo-small {
    width: 30px;
    height: auto;
  }
  .box-header-list .item-tow-part .box-logo.lp {
    width: 60px !important;
    margin-bottom: 5px;
  }
  .box-header-list .item-tow-part .box-logo .logo-big.lp {
    width: 50px !important;
    height: auto !important;
  }
  .box-header-list .item-tow-part .box-logo .logo-small.lp {
    width: 30px !important;
    height: auto !important;
  }
  .box-list-item .box-text-logo {
    margin-left: 5px;
  }
  .box-list-item .box-text-logo .text-big {
    font-size: 14px;
  }
  .box-list-item .box-text-logo .text-small {
    font-size: 12px;
  }
  .box-header-list .item-tow-part .box-earned {
    margin-left: 10px;
  }
  .box-header-list .item-tow-part .box-earned .total-coin > div {
    font-size: 14px;
    margin-bottom: 0;
  }
  .box-header-list .item-tow-part .box-earned .total-coin {
    margin-bottom: 0;
  }
  .box-header-list .item-tow-part .box-earned .text-earned {
    margin-bottom: 0;
    font-size: 15px;
  }
  .box-header-list .item-tow-part .box-apr .text-apr {
    margin-bottom: 0;
    font-size: 15px;
  }
  .box-header-list .item-tow-part .box-apr .number-apr {
    font-size: 12px;
  }
  .box-header-list .item-tow-part .box-earned .total-usd > div {
    font-size: 13px;
  }
  .box-header-list .item-tow-part .box-earned .total-usd {
    font-size: 12px;
  }
  .box-list-item .anticon.anticon-right.ant-collapse-arrow {
    display: block;
    color: #0dba88;
    top: 50%;
    transform: translateY(-50%);
  }
  .box-list-item .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 10px;
  }
  .box-header-list .item-tow-part:nth-child(1) {
    width: 10%;
  }
  .box-header-list .item-tow-part:nth-child(2) {
    width: 30%;
  }
  .box-header-list .item-tow-part:nth-child(3) {
    width: 30%;
  }
  .box-header-list .item-tow-part .box-img-calculating {
    margin-left: 10px;
  }
  .box-content-body .item-three-part {
    width: 100%;
    order: 3;
  }
  .box-content-body .item-four-part {
    width: 100%;
    order: 1;
    margin-bottom: 15px;
  }
  .box-content-body .item-four-part .box-harvest {
    margin-left: 0;
  }
  .box-content-body .item-four-part .box-start-staking {
    margin-left: 0;
  }
  .box-content-body .item-four-part .box-harvest .harvest-left .text-harvest {
    margin-bottom: 10px;
  }
  .box-content-body .item-three-part .text-view-info {
    text-align: right;
  }
  .line-user {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .line-user .text-left {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
  }
  .line-user .text-right {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
  }

  .line-apr {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .line-apr .text-left {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
  }
  .line-apr .text-right {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
  }
  /* .line-apr .text-left {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
  }
  .line-apr .text-right {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
  } */

  .line-total-staked {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .line-total-staked .text-left {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
  }
  .line-total-staked .text-right {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    display: flex;
  }
  .line-total-staked .text-right > div {
    margin-right: 5px;
  }
  .dis-mobile {
    display: flex;
  }
  .box-search-stake.cus {
    top: 79px !important;
  }
  .box-search-stake {
    top: 50px !important;
  }
  .back-to-top {
    margin: 0 15px;
  }
  .box-header-list .item-tow-part .height-80 {
    height: auto;
  }
  .box-header-list .item-tow-part .box-apr {
    margin-left: 0;
  }
  .modal-roi-stake {
    width: 100% !important;
  }
  .main-pool-card
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    transform: rotate(90deg);
  }
  .main-pool-card
    .ant-collapse
    > .ant-collapse-item.ant-collapse-item-active
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    transform: rotate(-90deg) !important;
  }
  .main-pool-card
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    fill: #ffd60a !important;
  }
  .box-content-body
    .item-three-part
    .text-ends-in
    .d-flex.align-items-center
    > div {
    color: #ffd60a !important;
  }
  /* .line-total-staked .text-right > div > span {
    color: #ffd60a !important;
  } */
  .text-ends-in .text-white.d-flex {
    width: 100%;
  }
  .box-content-body .item-three-part .text-view-info {
    text-align: left;
  }
  /* .box-header-list .item-tow-part .box-img-calculating {
    margin-left: 10px;
  } */
  .hide-mobile-cus {
    display: none !important;
  }
}
@media screen and (min-width: 350px) and (max-width: 400px) {
  .box-header-list .item-tow-part .box-logo {
    margin-bottom: 5px;
  }
  .box-list-item .box-text-logo {
    margin-left: 0;
  }
  .box-header-list .item-tow-part:nth-child(1) {
    width: 60%;
  }
  .box-header-list .item-tow-part:nth-child(3) {
    width: 30%;
  }
  .box-header-list .item-tow-part .box-earned .total-usd {
    font-size: 9px;
  }
  .box-header-list .item-tow-part .box-apr .number-apr {
    font-size: 11px;
  }
  .stake-only .text-stake-only {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #dbdad8;
    margin-left: 5px;
  }
  .box-list-item .anticon.anticon-right.ant-collapse-arrow {
    right: 0;
  }
  .box-header-list .item-tow-part .box-earned .text-earned {
    font-size: 12px;
  }
  .box-header-list .item-tow-part .box-earned .total-coin > div {
    font-size: 12px !important;
  }
  .box-header-list .item-tow-part .box-earned .total-usd > div {
    font-size: 12px !important;
  }
}
.btn-bsc.btn-bsc-common.btn-bsc-link.bsc-farm-item-more-bottom.pd-top {
  padding-top: 5px;
}
.back-to-top button {
  background-color: #f5f5dc00;
  border: none;
}

.modal-roi-stake .modal-roi-stake-title {
  /* border-bottom: 1px solid #fdd284; */
  border-radius: 18px 18px 0 0;
  color: #ffd60a !important;
  padding: 15px 0px;
}
.modal-roi-stake .modal-roi-stake-title hr {
  /* width: 150px; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #ffd60a !important;
}
@media screen and (max-width: 500px) {
  .dis-none-500 {
    display: none;
  }
  .box-header-list .item-tow-part:nth-child(1) {
    width: 20%;
  }
  .box-header-list .item-tow-part:nth-child(2) {
    width: 40%;
  }
  .box-header-list .item-tow-part:nth-child(3) {
    width: 25%;
  }
  .box-header-list .item-tow-part .box-earned .total-coin > div {
    font-size: 15px;
    margin-bottom: 0;
  }
  .box-header-list .item-tow-part .box-earned .total-usd > div {
    font-size: 13px;
  }
  .box-header-list .item-tow-part .box-earned .total-usd {
    font-size: 12px;
  }
  .box-header-list .item-tow-part .box-earned {
    margin-left: 10px;
  }
  .box-header-list .item-tow-part .box-apr {
    margin-left: 0px;
  }
  .box-list-item .box-text-logo .text-big {
    font-size: 12px;
  }
  .box-list-item .box-text-logo .text-small {
    font-size: 11px;
  }
  .box-header-list .item-tow-part .box-earned .text-earned {
    margin-bottom: 0;
    font-size: 14px;
  }
  .box-header-list .item-tow-part .box-earned .total-coin {
    font-size: 12px;
  }
  .box-header-list .item-tow-part .box-earned .total-coin > span > div {
    font-size: 12px;
  }
}
.button-hide-modal {
  padding: 5px;
  background: transparent;
  border: 0px solid transparent;
  color: #0dba88;
  position: absolute;
  right: 10px;
  top: 10px;
}
.btn-bsc-ghost:hover {
  background-color: #cacaca00;
}
.show-des {
  padding-left: 5px;
}
.color-text-usd {
  color: #fdd284;
  font-size: 16px;
  font-weight: 700;
}
.box-logo.lp {
  width: 84px !important;
}
img.logo-small.lp {
  background: #fff0 !important;
  width: 50px !important;
  height: auto !important;
  border-radius: inherit !important;
}
img.logo-big.lp {
  width: 75px !important;
  height: 75px !important;
}
.card-ido-pool {
  background: #333333;
}
.row-flex {
  display: flex;
  flex-wrap: wrap;
}
.mgl-5 {
  margin-left: 5px;
  font-weight: 500;
  color: #fdd284;
  display: flex;
}
.mgl-5.card-details {
  /* margin-left: 5px; */
  font-weight: 500;
  font-size: 16px;
  color: #fdd284;
  display: flex;
  align-items: center;
}
.text-usd-cus span {
  color: #fff;
}
.btn-bsc-disabled {
  background: #313131;
  border-radius: 0;
}
.btn-bsc-disabled:hover {
  background: #313131;
}
.btn-cnt {
  background: #ffd60a;
  border-radius: 0 !important;
}
.btn-cnt:hover {
  background: #ffd60a;
  opacity: 0.8;
}
.btn-bsc-primary.btn-cnt button div > span {
  color: #000;
}
.text-a a {
  color: #3785fa !important;
}
.main-pool-card .nav-tabs > .nav-item.active {
  background-color: #ffd60a !important;
  color: #000 !important;
  border-radius: 4px !important;
}
.main-pool-card .nav-tabs > .nav-item {
  border-radius: 4px !important;
  color: #fff;
}
.main-pool-card .nav-tabs .nav-link {
  margin-bottom: 0;
}
.main-pool-card .nav-tabs {
  border-radius: 4px !important;
}
.box-list-grid.nav-tabs > .nav-item.active {
  background: transparent !important;
}
.box-search-stake .search-new {
  color: #fff;
}
.box-search-stake .search-new span {
  display: block;
}
.box-search-stake .ant-input {
  margin-top: 5px;
  width: 200px;
}
.btn-bsc-ghost {
  background: #ffd60a !important;
  border-radius: 6px !important;
}
.btn-bsc-ghost button div > span {
  color: #000;
}
.btn-schedule {
  background: transparent;
  padding: 0;
  border: 0px solid transparent;
  margin-top: -10px;
  pointer-events: auto;
}
.btn-schedule svg {
  fill: #ffd60a;
  font-size: 20px;
}
.box-schedule {
  margin-top: -27px;
  margin-left: 5px;
}
.table-modal tr th {
  font-size: 14px;
  color: #fff;
  padding: 10px;
  background: #060606;
}
.table-modal {
  width: 100%;
  text-align: center;
  border: 1px solid #595959;
}
.table-modal tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  background: #101011;
}
.ant-modal-schedule {
  width: 700px !important;
}
.ant-modal-schedule .ant-modal-footer {
  display: none;
}
.ant-modal-schedule .ant-modal-title {
  text-align: center;
  font-size: 24px;
  text-transform: none;
}
.ant-modal-schedule .table-modal tr td,
.ant-modal-schedule .table-modal tr th {
  text-align: left;
}
.btn-modal {
  background: transparent;
  border: 0px solid transparent;
  padding: 0;
}
.btn-modal:hover,
.btn-modal:focus {
  background: transparent;
}
.modal-schedule .ant-modal-footer {
  display: none;
}
.modal-schedule {
  width: 700px !important;
}
.box-header-list .item-tow-part .box-logo .logo-small.cssLP {
  position: absolute;
  width: auto;
  height: 23px;
  right: -8px;
  bottom: 0;
  border-radius: 0;
  background: #ffffff00;
}
.block-home {
  font-size: 15px;
  font-weight: 600;
}
.balance-exchange .color-main-fff {
  font-size: 14px;
}
.text-balance-exchange {
  font-size: 14px;
}
.nodata {
  display: flex;
  justify-content: center;
  padding: 30px 0 10px;
  width: 100%;
}
