.nav-tabs {
  border-bottom: none !important;
  display: flex;
  width: fit-content;
  margin-left: calc(50% - 80px);
  height: 40px;
  border-radius: 22px;
  background-color: #2E2E2E !important;
  margin-top: 20px;
  & > .nav-item {
    color: #FDD284;
    font-size: 14px;
    font-family: poppins_bold;
    border: none !important;
    text-align: center;
    border-radius: 22px !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px 22px !important;
  }

  & > .nav-item.active {
    background-color: #FDD284 !important;
    color: white !important;
  }
}
// css
.bsc-farm-item-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: linear-gradient(92.67deg, #1B1B1C -30.54%, #FFD60A 664%);
  padding: 15px;
  border-radius: 4px 4px 0 0;
  & > div {
    justify-content: center;
    align-items: center;
  }
  .bsc-farm-item-header-left {
    display: flex;
    & > svg:nth-child(1) {
      margin-right: 5px;
    }
  }
  .bsc-farm-item-header-center {
    display: flex;
    & > span {
      color: #EFEBE5;
      font-family: poppins_semibold;
      font-size: 18px;
    }
  }
  .bsc-farm-item-header-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    & > span:nth-child(2) {
      color: #FDD284;
      border: 1.5px solid #FDD284;
      border-radius: 8px;
      height: 23px;
      font-size: 10px;
      font-family: poppins_medium;
      padding-left: 5px;
      padding-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > svg {
        margin-right: 5px;
      }
    }

    & > span:nth-child(1) {
      color: #FAAC20;
      background-color: rgba(#E7A021, 0.13);
      border-radius: 8px;
      height: 22px;
      width: fit-content;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 5px;
    }
  }
}

.bsc-farm-item-content {
  padding: 15px;
  & > div {
    margin-top: 10px;
  }
  span {
    font-size: 20px;
  }
  .bsc-farm-item-content-top {
    & > div {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      & > span:nth-child(1) {
        color: #FFFFFF;
      }
      & > span:nth-child(2) {
        color: #FDD284;
        font-family: poppins_semibold;
      }
    }

    & > div:nth-child(2) {
      margin-top: 10px;  
    }
  }
  .bsc-farm-item-content-mid,
  .bsc-farm-item-content-bottom {
    & >span {
      display: flex;
      width: 100%;
      color: #FDD284;
      align-items: center;
      span {
        color: white;
        margin-left: 5px;
      }
    }

    & > div {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      & > .bsc-farm-item-approve-contract,
      & > .bsc-farm-item-enable {
        width: 100%;
      }

      & > div {
        display: flex;
        & > div:nth-child(1) {
          margin-right: 10px;
        }
      }

      & > span {
        color: #FDD284;
        font-family: poppins_semibold;
      }
    }
  }
}

.bsc-farm-item-footer {
  height: 57px;
  background-color: #1B1B1C;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  & > .btn-bsc {
    & > button {
      & > div {
        span {
          margin-right: 10px;
          color: #FFD60A;
        }
      }
    }
  }
}

.bsc-farm-item-more {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 15px;
  & > div {
    display: flex;
  }
  .bsc-farm-item-more-top {
    justify-content: space-between;
    margin: 0;
    & > span:nth-child(1) {
      font-size: 14px;
      color: white;
    }

    & > span:nth-child(2) {
      font-size: 16px;
      color: #FDD284;
      font-family: poppins_semibold;
    }
  }
  .bsc-farm-item-more-top,
  .bsc-farm-item-more-mid {
    margin-bottom: 10px;
  }
  .bsc-farm-item-more-mid,
  .bsc-farm-item-more-bottom {
    padding: 0px;
    & > button {
      padding: 0px;
      & > div {
        justify-content: flex-start;
        span {
          color: #FFD60A !important;
          margin-right: 5px;
        }
      }
    }
  } 
}

.cursor-pointer{
  cursor: pointer;
}
.bsc-farm-item-header-center .text-small {
  color: #9F9F9F;
  text-align: left;
  font-size: 14px;
  margin-bottom: 0;
}
.text-earned {
  font-size: 14px;
  color: #9F9F9F;
}
.row-flex-cus > div > span {
  color: #fff;
  font-size: 18px;
}
.btn-bsc-primary {
  background: #FFD60A !important;
  border-radius: 4px !important;
}
.mar-0-15 {
  margin: 0 15px 10px 15px;
  padding-bottom: 10px;
}
.btn-bsc-common {
  padding: 7px 10px;
  border-radius: 6px !important;
}
.text-cus-re {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.text-cus-name {
  color: #9F9F9F;
  font-size: 14px;
  margin-bottom: 4px;
  margin-top: 4px;
}
.align-items-center > div {
  color: #fff;
  font-size: 15px;
}
.box-view-contract {
  display: flex;
  flex-wrap: wrap;
}
.box-view-contract .bsc-farm-item-more-bottom {
  width: 50%;
  margin: 0 !important;
}
.box-view-contract .bsc-farm-item-more .bsc-farm-item-more-bottom > button > div span {
  color: #FFD60A !important;
  margin-right: 5px;
  font-size: 14px;
}
.box-view-contract .btn-bsc-common button span {
  font-size: 14px;
}
.box-search-stake.cus {
  position: absolute;
  right: 225px;
  top: 6px;
}
.box-search-stake.cus .ant-select-selector {
  width: 150px;
  background-color: #1B1B1C;
    color: #fff;
    border: 1px solid #595959;
}
.box-search-stake.cus .ant-select-arrow {
  top: 76%;
}
.box-search-stake.cus .anticon svg {
  fill: #fff;
}
.ant-select-dropdown {
  background-color: #1B1B1C;
}