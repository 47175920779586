.bsc-header {
  height: 77px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(90deg, rgb(27, 27, 28) 0%, rgba(255, 214, 10, 0.18) 86%);
  overflow-x: auto;
  .bsc-header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
    & > .btn-bsc-common {
      height: 32px;
      border-radius: 18px;
      padding-bottom: 0px;
      padding-top: 0px;
      margin-right: 10px;
    }
  }

  .bsc-header-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & > img {
      width: 100%;
    }
  }
}
.text-custom {
  color: #e6e6e6 !important;
}
@media screen and (max-width: 500px) {
  .text-custom {
    color: #e6e6e6 !important;
    font-size: 14px !important;
  }
}
.ant-menu-horizontal {
  height: 77px;
  border: 0;
  /* border-bottom: 1px solid #f0f0f0; */
  box-shadow: none;
  align-items: center;
  line-height: 46px;
  color: #ffff;
}

.btnConnect{
  color: #000000;
  background-color: #FFD60A;
  width: 181px;
  height: 40px;
  border-color:#FFD60A ;
  font-weight: 500;
  border: 0px solid transparent;
}
.btnConnect:hover{
  background-color: #fdd300;
  width: 182px;
  height: 41px;
  color: #000000;
  border: 0px solid transparent;
}
.ant-modal-header {
  background: #1B1B1C;
  border-bottom: 1px solid #f0f0f036;
  border-radius: 2px 2px 0 0;
}
.ant-modal-title {
  margin: 0;
  text-align: center;
  color: #FFD60A;
  word-wrap: break-word;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  /* identical to box height */

  letter-spacing: 0.025em;
  text-transform: uppercase;
}
.learnMore{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: 0.025em;
  color: #F9C801;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: center;
  background: #1B1B1C;
  border-top: 1px solid #1B1B1C;
  border-radius: 0 0 2px 2px;
}
.ant-modal-body {
  background: #1B1B1C;
  padding: 24px;
  color: #ffff;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-close{
  color: #ffff;
}
.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover{
  color: #F9C801 !important;

}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after{
  border-bottom: 2px solid #F9C801 !important;
}
.logoMenu{
  margin-right: 40px;
}
.logoImg{
  width: 70%;
  height: 100%;
}
.ant-menu-horizontal > .ant-menu-item::after {
  bottom: -15px;
}
.ant-menu-overflow li:first-child:after {
  display: none;
}
.ant-menu-overflow li:nth-child(6):after {
  display: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  padding: 0 30px !important;
}
.ant-menu-title-content a {
  color: #fff;
  position: relative;
  display: block;
  margin: 0;
  padding: 0 5px;
  white-space: nowrap;
  cursor: pointer;
  transition: background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); 
}
.ant-menu-horizontal > .ant-menu-item a {
  color: #fff;
  font-weight: 500;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #FFD60A;
  text-decoration: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: 2px solid #FFD60A;
}
.hide-menu {
  display: none;
}
.show-mobile {
  display: block;
}
.header-mobile {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, rgb(27 27 28) 0%, rgb(255 214 10 / 18%) 86%);
  position: relative;
  z-index: 1001;
}
.header-mobile .ant-btn-primary {
  background: transparent;
  border: 0px solid transparent;
  font-size: 18px;
}
.header-mobile .ant-btn-primary:hover, .header-mobile .ant-btn-primary:focus {
  background: transparent;
  border: 0px solid transparent;
}
.header-mobile .ant-btn {
  font-size: 20px;
  height: auto;
  padding-right: 0;
}
.menu-mobile {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 998;
}
.header-mobile .header-right {
  position: relative;
  z-index: 999;
}
.menu-mobile .ant-menu {
  height: 100vh;
  background: transparent;
  margin-top: 80px;
}
.menu-mobile .ant-menu:before {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 997;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  filter: blur(2px);
}
.menu-mobile .ant-menu-dark.ant-menu-inline .ant-menu-item {
  position: relative;
  z-index: 1000;
  padding-left: 15px !important;
}
.menu-mobile .ant-menu-dark .ant-menu-item > span > a {
  font-weight: bold;
  padding-left: 0;
}
.menu-mobile .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #FFD60A !important;
}
.bottom-menu-mobile {
  position: absolute;
  bottom: 30px;
  left: 0;
  z-index: 1001;
  width: 100%;
}
.bottom-menu-mobile .list-socail li {
  list-style-type: none;
}
.bottom-menu-mobile .list-social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}
.social-mobile {
  padding: 15px;
}
.copy-right {
  text-align: center;
  color:#8F8F8F;
  font-size: 12px;
}
.box-content-wl .wl-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-content-wl .wl-top .value-kdg .balance {
  display: block;
}
.box-content-wl .wl-top .value-kdg {
  text-align: right;
}
.box-content-wl {
  margin-top: -40px;
}
.box-content-wl .wl-top .value-kdg .value {
  font-size: 30px;
  font-weight: 600;
  color: #FAC800;
  line-height: 1.2;
}
.box-content-wl .wl-top .value-kdg .balance {
  color: #9F9F9F;
  font-size: 14px;
  font-weight: normal;
}
.box-content-wl  .wl-middle .list-wl {
  padding-left: 0;
  margin-top: 30px;
}
.box-content-wl  .wl-middle .list-wl li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}
.box-content-wl  .wl-middle .list-wl li .text-left {
  color: #9F9F9F;
  font-size: 14px;
  font-weight: normal;
}
.box-content-wl  .wl-middle .list-wl li .text-right {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}
.main-color-text {
  color: #FAC800 !important;
}
.list-tier {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 468px;
}
.list-tier:before {
  content: '';
  background: #343437;
  width: 400px;
  height: 5px;
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translate(-50% , -50%);
  z-index: 0;
}
.list-tier li {
  list-style-type: none;
  width: 20%;
  position: relative;
  z-index: 2;
}
.list-tier li .box-img img {
  filter: brightness(0.5);
}
.list-tier li .box-img.active img {
  filter: brightness(1);
}
.list-tier li:first-child .box-img img {
  width: 35px;
  margin-top: -3px;
  margin-bottom: 5px;
}
.list-tier li  .text-tier {
  margin-top: 10px;
}
.list-tier li:first-child .text-tier {
  margin-top: 2px;
}
.list-tier li  .text-tier .title-tier {
  margin-bottom: 0;
  font-size: 14px;
  color: #fff;
}
.list-tier li  .text-tier span {
  font-size: 12px;
  color: #9F9F9F;
  font-weight: normal;
}
.wl-bottom {
  background: #101011;
  padding: 20px;
  margin: 20px -23px;
}
.wl-footer {
  margin-top: 30px;
}
.wl-footer .title-footer {
  color: #9F9F9F;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 15px;
}
.wl-footer .swap-now {
  color: #F9C801;
  text-decoration: underline;
  font-size: 16px;
}
.btn-wl {
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.btn-view {
  background: #FFD60A;
  font-size: 16px;
  color: #000;
  padding: 10px 40px;
  width: 235px;
  border: 0px solid transparent;
  cursor: pointer;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin-top: 0 !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  border: 0px solid transparent;
  margin-top: -1px !important;
  margin-bottom: 0 !important;
  /* width: 181px;
  height: auto; */
}
.bsc-header .bsc-header-right > .btn-bsc-common {
  width: 180px;
  height: 40px;
  // border-radius: 0 !important;
}
.btn-bsc-primary button div > span {
  color: #000;
}
.bsc-header .bsc-header-left.cus {
  width: 100%;
}
.bsc-header .bsc-header-left.cus .header-mobile {
  width: 100%;
}
.btn-active {
  background: transparent;
  padding: 0;
  border: 0px solid transparent;
}
.btn-active svg {
  fill: #fff;
  font-size: 20px;
}
.btn-mobile .btn-bsc-common {
  height: auto;
  width: 180px;
}
.menu-mobile .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
}
.cus-btn-cnt .btn-bsc-common button span {
  font-size: 14px;
}
@media screen and (max-width:768px) {
  .box-modal-cnt {
    width: auto;
  }
  .list-tier {
    width: auto;
  }
  .list-tier:before {
    width: 80%;
  }
  .text-right {
    font-size: 12px;
  }
}

.buykdg a {
  width: 120px;
  display: block;
  border: 1px solid #FFD60A;
  text-align: center;
}

.btn-airdrop a {
  position: relative;
}
.btn-airdrop a::after{
  content: '';
  width: 34px;
  height: 22px;
  // background: url('../../assets/images/new-fire-3.gif') no-repeat center;
  background-size: 100%;
  position: absolute;
  top: 3px;
  right: -31px;
  left: auto;
}

@media screen and (max-width:768px) {
  .btn-airdrop a::after{
      right: auto;
      left: 54px;
      top: 0;
  }
}