.title-header-md span {
    color: #FFD60A;
    margin: 0 auto;
    font-size: 24px;
}
.value-kdg span{
    color:#FAC800;
    font-size: 30px;
}
.value-kdg > div > div  {
    text-align: right;
    width: 100%;
}
.label-value > div {
    color: #9F9F9F;
}
.box-detail-modal .item-wl {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}
.box-detail-modal .item-wl .text-l {
    color: #9F9F9F;
    font-size: 14px;
}
.box-detail-modal .item-wl .text-r {
    color: #fff;
    font-size: 16px;
}
.color-main-wl {
    color:#FAC800 !important;
}
.box-detail-modal {
    margin-bottom: 20px;
}
.btn-wl button {
    background: #FFD60A;
    color: #000;
    font-size: 16px;
    font-weight: normal;
    border-radius: 2px !important;
    margin: 0 10px;
}
.btn-wl {
    margin: 0 -10px;
}
.btn-wl button a:hover {
    text-decoration: none;
    color: #000;
}
.btn-toast{
    background: transparent;
    border: 0px solid transparent;
    padding: 0;
    margin-left: 5px;
}