.box-modal-cnt {
    width: 550px;
}
.box-modal-cnt > div > span {
    color: #FFD60A;
    margin: 0 auto;
    font-size: 26px;
    text-transform: uppercase;
    padding: 10px 0;
}
.bsc-wallet-connection {
    width: 48%;
}
.box-modal-cnt .bsc-wallet-connection > .btn-bsc-common > button > div {
    justify-content: flex-end;
    flex-direction: row-reverse;
}
.box-modal-cnt .bsc-wallet-connection > .btn-bsc-common span {
    color: #fff;
    font-weight: normal;
    font-size: 14px;
    margin-left: 12px;
    margin-right: 0;
}
.box-modal-cnt .bsc-wallet-connection > .btn-bsc-common {
    background: transparent !important;
    transition: 0.3s ease;
    margin-bottom: 15px;
}
.box-modal-cnt .bsc-wallet-connection > .btn-bsc-common:hover {
    background: #292929 !important;
}
.link-a a {
    color: #FFD60A;
    margin-top: 15px;
}
.link-a a:hover {
    color: #FFD60A;
    text-decoration: none;
}