.bsc-wallet-connection {
  margin-top: 10px;
  border-radius: 16px;
  height: 50px;
  & > .btn-bsc-common {
    background-color: rgb(64, 64, 64) !important;
    & > button {
      & > div {
        justify-content: space-between;
      }
    }
    span {
      color: rgb(13, 186, 136);
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      margin-right: 16px;
    }
  }
}